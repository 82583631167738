import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreateOrEditDialogComponent } from '@app/shared/components/create-or-edit-dialog/create-or-edit-dialog.component';
import { AuthService } from '@core/services/auth.service';
import { OrganizationService } from '@data/services/organization.service';
import { ConfigModel } from '@shared/components/custom-table/decorators/config.model';
import { SnackbarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrl: './organizations.component.scss',
})
export class OrganizationsComponent {
  tableData = [];
  tableConfig: ConfigModel = {
    api: '',
    title: 'Organisations',
    addOptions: {
      active: true,
      type: 'modal',
      color: 'accent',
      label: 'Créer une organisation',
    },
    editOptions: { active: true, type: 'redirect' },
    deleteOptions: { active: true, type: 'modal' },
    searchOptions: { active: true },
    selectOptions: { active: false },
    customActions: [
      // {
      //   icon: (value: any) => 'delete-light',
      //   value: 'delete',
      //   label: (value: any) => {
      //     return 'Supprimer';
      //   },
      // },
      // {
      //   icon: (value: any) => 'edit-square-light',
      //   value: 'edit-org',
      //   label: (value: any) => {
      //     return 'Modifier';
      //   },
      // },
    ],
    columns: [
      {
        label: 'Nom',
        key: 'name',
        type: 'string',
        pattern: 'name',
        cell: (element: any) => {
          return element.name ?? 'Non renseigné';
        },
      },
    ],
  };

  dataLogo: any;

  constructor(
    private authService: AuthService,
    private organizationService: OrganizationService,
    private router: Router,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    if ((this.authService.userValue!.role?.level ?? 99) > 1) {
      this.router.navigate(['/admin']);
    } else {
      this.tableConfig.api = `organisations`;
      this.getAllOrganizations();
    }
  }

  getAllOrganizations() {
    this.organizationService.getOrganizations().subscribe({
      next: (response) => {
        this.tableData = response;
      },
    });
  }

  getActionModalFromCustomTable(event: any) {
    switch (event.type) {
      case 'add':
        this.openCreateOrUpdateOrganizationDialog(event.data[0]);
        break;
      case 'edit':
        this.dataLogo = event.data[0].logo;
        this.router.navigate([
          `/admin/organisations/${event.data[0].id}/managers`,
        ]);

        break;
      case 'delete':
        if (
          confirm(
            `Êtes-vous certain de vouloir supprimer ${event.data[0].first_name} ${event.data[0].last_name} (${event.data[0].email}) ?\nCette action est irreversible.`
          )
        ) {
          this.organizationService
            .deleteOrganization(event.data[0].id)
            .subscribe({
              next: (response: any) => {
                this.getAllOrganizations();
                this.snackbarService.openSnackBar(
                  'Organisation supprimée',
                  'Fermer'
                );
              },
              error: (error: any) => {},
            });
        }

        break;
    }
  }

  openCreateOrUpdateOrganizationDialog(data: any) {
    const dialogRef = this.dialog.open(CreateOrEditDialogComponent, {
      width: '700px',
      data: {
        field: [
          {
            fieldGroupClassName: 'flex flex-col gap-4',
            fieldGroup: [
              {
                key: 'name',
                type: 'input',
                defaultValue: [],
                props: {
                  label: 'Nom',
                  required: true,
                },
              },
              {
                key: 'logo',
                type: 'input-img',
                className: 'basis-full',
                props: {
                  label: "Logo pour l'organisation",
                  type: 'text',
                  required: false,
                },
              },
            ],
          },
        ],
        model: data ?? {},
        config: {
          header: {
            icon: '',
            label: data
              ? 'Modifier une organisation'
              : 'Créer une organisation',
          },
          actions: {
            cancel: 'Annuler',
            submit: 'Valider',
          },
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result != true) {
        if (!data) {
          if (result.logo) {
            result.logo = this.generateFormDataForUploadImg(result.logo);
          }

          this.organizationService.createOrganization(result).subscribe({
            next: (response) => {
              if (result.logo) {
                this.organizationService
                  .uploadOrganizationLogo(response.id, result.logo)
                  .subscribe({
                    next: () => {
                      this.getAllOrganizations();
                      this.snackbarService.openSnackBar(
                        'Organisation créée',
                        'Fermer'
                      );
                    },
                    error: () => {
                      this.snackbarService.openSnackBar(
                        "Erreur lors de la création de l'organisation",
                        'Fermer'
                      );
                    },
                  });
              } else {
                this.getAllOrganizations();
                this.snackbarService.openSnackBar(
                  'Organisation créée',
                  'Fermer'
                );
              }
            },

            error: () => {
              this.snackbarService.openSnackBar(
                "Erreur lors de la création de l'organisation",
                'Fermer'
              );
            },
          });
        } else {
          // TODO: Patch to remove the logo ???
          if (result.logo != data.logo) {
            result.logo = this.generateFormDataForUploadImg(result.logo);
          }

          this.organizationService
            .updateOrganization(data.id, result)
            .subscribe({
              next: () => {
                if (result.logo != data.logo) {
                  this.organizationService
                    .uploadOrganizationLogo(data.id, result.logo)
                    .subscribe({
                      next: () => {
                        this.getAllOrganizations();
                        this.snackbarService.openSnackBar(
                          '1 Organisation modifiée',
                          'Fermer'
                        );
                      },
                      error: () => {
                        this.snackbarService.openSnackBar(
                          "1 Erreur lors de la modification de l'organisation",
                          'Fermer'
                        );
                      },
                    });
                } else if (result.logo == null) {
                  // TODO : If result.logo is null, remove the logo
                } else {
                  this.getAllOrganizations();
                  this.snackbarService.openSnackBar(
                    'Organisation modifiée',
                    'Fermer'
                  );
                }
              },
              error: () => {
                this.snackbarService.openSnackBar(
                  "Erreur lors de la modification de l'organisation",
                  'Fermer'
                );
              },
            });
        }
      }
    });
  }

  generateFormDataForUploadImg(file: File | File[]) {
    const formData = new FormData();
    if (Array.isArray(file)) {
      file.forEach((f) => formData.append('file', f, f.name));
    } else {
      formData.append('file', file, file.name);
    }
    return formData;
  }
}
