<section>
  <mat-card class="mat-elevation-z0">
    <mat-card-content>
      <layxo-custom-table
        [data]="tableData"
        [config]="tableConfig"
        (actionModalEvent)="getActionModalFromCustomTable($event)"
      ></layxo-custom-table>
    </mat-card-content>
  </mat-card>
</section>
